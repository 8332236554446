import { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { BigNumber } from "@ethersproject/bignumber";
import { useWeb3Onboard } from "@src/hooks/useWeb3Onboard";

import { Button } from "@src/components";
import { useAppDispatch, useContract } from "@src/hooks";
import { VESTING_DETAILS, CHAIN_ID } from "@src/config";
import { NETWORKS } from "@src/constants";
import VestingABI from "@src/abi/DecubateVestingV2.json";
import provider, { Vesting } from "@src/web3";
import { updateWallets } from "@src/features/wallets";

import { parseBalance } from "@src/util";
import { parseUnits } from "@ethersproject/units";
import { useToken } from "@src/hooks/useToken";

const network = NETWORKS[CHAIN_ID];

const BATCH_SIZE = 150;

const batchArray = (arr, batch_size) => {
    const batches = [];
    let curr_batch = [];
    for (let i = 0; i < arr.length; i++) {
        curr_batch.push(arr[i]);
        if (curr_batch.length === batch_size) {
            batches.push(curr_batch);
            curr_batch = [];
        }
    }
    if (curr_batch.length > 0) {
        batches.push(curr_batch);
    }
    return batches;
};

function roundCryptoValueString(str: string, decimalPlaces = 18) {
    const arr = str.split(".");
    const fraction = (arr[1] || "0").slice(0, decimalPlaces);
    return arr[0] + "." + fraction;
}

const Batch: React.FC<{ num: number; max: number }> = ({ num, max }) => {
    const start = 1 + (num - 1) * BATCH_SIZE;
    const end = Math.min(num * BATCH_SIZE, max);

    return (
        <div className="p-4 flex items-center space-x-4 rounded bg-level-one my-4">
            <h4>
                <strong>Transaction {num}</strong>
            </h4>
            <p className="text-secondary">
                {start} - {end}
            </p>
        </div>
    );
};

const checkForDuplicates = (
    wallets: string[][],
    setHasDuplicates: (b: boolean) => void,
) => {
    const all_wallets = wallets.reduce((acc, curr) => [...acc, ...curr], []);
    setHasDuplicates(all_wallets.length !== new Set(all_wallets).size);
};

export const Upload: React.FC<{
    idx: number;
    strategy_id: number;
}> = ({ idx, strategy_id }) => {
    const { addToast } = useToasts();
    const { account } = useWeb3Onboard();
    const [update, forceUpdate] = useState(0);
    const [estimate, setEstimate] = useState(BigNumber.from(0));
    const [balance, setBalance] = useState(BigNumber.from(0));
    const { contract, token_address } = VESTING_DETAILS[idx];
    const [loading, setLoading] = useState(false);
    const [estimating, setEstimating] = useState(false);
    const [processing, setProcessing] = useState(false);
    const vesting = useContract(contract, VestingABI, true);
    const [file, setFile] = useState(null);
    const [wallets, setWallets] = useState([]);
    const [amounts, setAmounts] = useState([]);
    const [has_duplicates, setHasDuplicates] = useState(false);
    const dispatch = useAppDispatch();

    const { decimals, loading: token_loading } = useToken(token_address);

    const handleChange = (e) => {
        setWallets([]);
        setAmounts([]);
        setFile(e.target.files[0]);
    };

    useEffect(() => {
        if (file && !token_loading) {
            const csv = file;
            const reader = new FileReader();
            const vesting = Vesting(contract);
            reader.onload = async function (e) {
                setProcessing(true);
                const text = e.target.result as string;

                const to_filter = text
                    .split("\r\n")
                    .map((s) => s.split(","))
                    .filter((arr) => arr.length === 2)
                    .map(([addr, amount]) => {
                        return [
                            addr.trim(),
                            parseUnits(
                                roundCryptoValueString(amount.trim(), decimals),
                                decimals,
                            ),
                        ];
                    });

                const { wallets, amounts } = (
                    await Promise.all(
                        to_filter.map(async ([addr, amount]) => {
                            const has_whitelist = await vesting.hasWhitelist(
                                strategy_id,
                                addr,
                            );
                            return [addr, amount, !has_whitelist];
                        }),
                    )
                ) // eslint-disable-next-line
                    .filter(([_, __, filter]) => filter)
                    .reduce(
                        (acc, curr) => ({
                            wallets: [...acc.wallets, curr[0]],
                            amounts: [...acc.amounts, curr[1]],
                        }),
                        { wallets: [], amounts: [] },
                    );

                setWallets(batchArray(wallets, BATCH_SIZE));
                setAmounts(batchArray(amounts, BATCH_SIZE));
                setProcessing(false);
            };
            reader.readAsText(csv);
        }
    }, [file, update, token_loading]);

    useEffect(() => {
        if (wallets.length > 0 && amounts.length > 0) {
            handleEstimate().then(([gas, bal]) => {
                setEstimate(gas);
                setBalance(bal);
            });
            checkForDuplicates(wallets, setHasDuplicates);
        }
    }, [wallets.length, amounts.length, setHasDuplicates]);

    const handleUpload = async () => {
        setLoading(true);
        addToast("Awaiting successful transaction(s)", {
            appearance: "info",
        });

        for (let i = 0; i < wallets.length; i++) {
            const w = wallets[i];
            const a = amounts[i];
            try {
                const tx = await vesting.batchAddWhitelist(w, a, strategy_id);
                await tx.wait();
                addToast(`Transaction ${i + 1} successful`, {
                    appearance: "success",
                });
            } catch (err) {
                addToast(err.data?.message || err.message, {
                    appearance: "error",
                });
            }
        }
        handleClear();
        dispatch(updateWallets(idx, strategy_id));
        setLoading(false);
    };

    const handleEstimate = async () => {
        setEstimating(true);
        const vesting = Vesting(contract);
        const total = (
            await Promise.all(
                wallets.map(async (w, idx) => {
                    const a = amounts[idx];
                    try {
                        const gas = await vesting.estimateGas.batchAddWhitelist(
                            w,
                            a,
                            strategy_id,
                            { from: account },
                        );
                        return gas;
                    } catch (err) {
                        return BigNumber.from(0);
                    }
                }),
            )
        ).reduce((acc, curr) => acc.add(curr), BigNumber.from(0));

        const [gas_price, balance] = await Promise.all([
            provider.getGasPrice(),
            provider.getBalance(account),
        ]);
        setEstimating(false);
        return [total.mul(gas_price), balance];
    };

    const handleClear = () => {
        setWallets([]);
        setAmounts([]);
    };

    const num_wallets = wallets.reduce((acc, curr) => acc + curr.length, 0);
    const not_enough_funds = BigNumber.from(estimate).gt(balance);

    return (
        <div>
            <div className="flex items-center space-x-4 mt-4">
                <div className="flex-1">
                    <h5>
                        <strong>{file ? file.name : "Choose .CSV file"}</strong>
                    </h5>
                    <p className="text-sm text-secondary">
                        {BATCH_SIZE} wallets max. per transaction
                    </p>
                </div>

                <label
                    onClick={() => forceUpdate(update + 1)}
                    htmlFor="file-input"
                    className="cursor-pointer rounded px-4 py-2 bg-level-one w-32 text-center"
                >
                    {processing ? (
                        <div className="loader">
                            <img
                                src="/img/Spinner-Small.png"
                                className="mx-auto"
                                style={{
                                    width: "20px",
                                    height: "20px",
                                }}
                                alt="Loading..."
                            />
                        </div>
                    ) : (
                        "Choose file"
                    )}
                </label>
                <input
                    onChange={handleChange}
                    type="file"
                    id="file-input"
                    name="file"
                    accept=".csv"
                    style={{ display: "none" }}
                />
            </div>
            {wallets.length > 0 && (
                <div className="mt-4">
                    <div className="flex items-center my-2">
                        <p className="flex-1 text-secondary">Wallets found</p>
                        <strong>{num_wallets}</strong>
                    </div>
                    <div className="flex items-center my-2">
                        <p className="flex-1 text-secondary">Estimated cost</p>
                        <div
                            className={
                                estimating
                                    ? "animate-pulse bg-level-one w-20 h-6"
                                    : ""
                            }
                        >
                            {!estimating && (
                                <strong
                                    className={`${
                                        not_enough_funds ? "text-red-500" : ""
                                    }`}
                                >{`${parseBalance(estimate)} ${
                                    network.symbol
                                }`}</strong>
                            )}
                        </div>
                    </div>
                    <div className="flex items-center my-2">
                        <p className="flex-1 text-secondary">Balance</p>
                        <div
                            className={
                                estimating
                                    ? "animate-pulse bg-level-one w-20 h-6"
                                    : ""
                            }
                        >
                            {!estimating && (
                                <strong>{`${parseBalance(balance)} ${
                                    network.symbol
                                }`}</strong>
                            )}
                        </div>
                    </div>

                    {has_duplicates && (
                        <p className="text-sm text-red-500">
                            Remove duplicates before upload
                        </p>
                    )}

                    {wallets.map((_, idx) => (
                        <Batch
                            key={`batch-${idx}`}
                            num={idx + 1}
                            max={num_wallets}
                        />
                    ))}

                    <div className="mt-8 mb-4 flex space-x-4" id="buttonUpload">
                        <Button dark className="flex-1" onClick={handleClear}>
                            Clear
                        </Button>
                        <Button
                            className="flex-1"
                            loading={loading}
                            disabled={
                                loading || not_enough_funds || has_duplicates
                            }
                            onClick={handleUpload}
                        >
                            Upload
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};
