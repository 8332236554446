import React from "react";
import Link from "next/link";

import { Account, AddToWallet } from "@src/components";
import { Button } from "@src/components/button";
import { LOGO, LOGO_WIDTH, VESTING_DETAILS } from "@src/config";
import { useWeb3Onboard } from "@src/hooks/useWeb3Onboard";

import { provider_is_meta_mask } from "@src/constants";

export const Header: React.FC = () => {
    const home_path = VESTING_DETAILS.length > 1 ? "/tokens" : "/vesting/0";
    const { disconnect, wallet, account } = useWeb3Onboard();

    return (
        <div
            className="px-5 bg-transparent text-center pt-3 pb-1 flex items-center justify-between text-xs sm:text-md max-w-screen-lg mx-auto"
            data-testid="container"
        >
            <div className="py-1">
                <Link href={home_path}>
                    <img
                        data-testid="logo-img"
                        src={LOGO}
                        className="mx-auto"
                        width={LOGO_WIDTH}
                    />
                </Link>
            </div>
            <div className="flex py-1 items-center space-x-4">
                {provider_is_meta_mask && (
                    <div className="px-5 py-1 hidden md:block">
                        <AddToWallet className="hover:underline" />
                    </div>
                )}
                {account && (
                    <Button onClick={() => disconnect(wallet)} dark>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                            id="manage"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                            />
                        </svg>
                    </Button>
                )}
                <Account />
            </div>
        </div>
    );
};
